import { cloneDeep, findIndex } from 'lodash'
import moment from 'moment'
import { actionChannel, all, call, fork, put, select, take, takeLeading, takeEvery } from 'redux-saga/effects'
import API from 'src/api'
import { checkIfTimeExists } from 'src/redux/helpers/functions'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import {
  ActionCreators as CredentialsStoreActionCreators,
  getEntityHierarchyConfiguration,
  getVisitorSubscriptionCredential,
} from 'src/redux/store/credentials'
import { VisitorLogItem } from 'src/redux/store/visitors/types/log'
import { INDUCTION_TYPES_ENUM } from 'src/typings/kenai/enums'
import { RangePickerValue } from 'src/typings/vendor/rangepicker'
// import update from 'immutability-helper'
import AccessManager from 'src/utils/AccessManager'
import { ActionCreators as VisitorLogActionCreators, getVisitorsDateRanges, getVisitorsLogData } from '../../store/visitors/log'
import ActionCreator from '../saga-action-creator'
import calculateVisitorSearchString from './helpers/calculateVisitorSearchString'
import { getLiveUpdateEarliestTimestamp } from 'src/redux/store/dashboard/globalevents'

const AWSHelper = new API.AWSHelpers()

// Action Creators
export const ActionCreators = {
  SagaRetrieveVisitorLogData: new ActionCreator<
    'SagaRetrieveVisitorLogData',
    { beginTime: number; endTime: number; shouldSubscribeToUpdates?: boolean }
  >('SagaRetrieveVisitorLogData'),
  SagaSetVisitorLogDateRange: new ActionCreator<'SagaSetVisitorLogDateRange', RangePickerValue>('SagaSetVisitorLogDateRange'),
  SagaSignOutIdentifiedIndividuals: new ActionCreator<
    'SagaSignOutIdentifiedIndividuals',
    { data: Pick<VisitorLogItem, 'EntityHierarchy' | 'evtTimeStampProfileID'>[]; loading: 'global' | 'local' }
  >('SagaSignOutIdentifiedIndividuals'),
  SagaRevertSignOut: new ActionCreator<'SagaRevertSignOut', VisitorLogItem>('SagaRevertSignOut'),
  SagaDeleteIdentifiedIndividuals: new ActionCreator<
    'SagaDeleteIdentifiedIndividuals',
    { data: VisitorLogItem[]; loading: 'global' | 'local' }
  >('SagaDeleteIdentifiedIndividuals'),
  SagaUpdateVisitorCustomFieldValue: new ActionCreator<
    'SagaUpdateVisitorCustomFieldValue',
    Pick<VisitorLogItem, 'EntityHierarchy' | 'evtTimeStampProfileID'> & {
      fieldId: string
      updatedValue: any
    }
  >('SagaUpdateVisitorCustomFieldValue'),
  SagaLinkVisitorBadge: new ActionCreator<'SagaLinkVisitorBadge', VisitorLogItem>('SagaLinkVisitorBadge'),
  SagaVisitorSetInductionCompleteInScreeningRoom: new ActionCreator<
    'SagaVisitorSetInductionCompleteInScreeningRoom',
    {
      EntityHierarchy: VisitorLogItem['EntityHierarchy']
      ProfileId: VisitorLogItem['ProfileId']
      evtTimeStampProfileID: VisitorLogItem['evtTimeStampProfileID']
      channels: any
    }
  >('SagaVisitorSetInductionCompleteInScreeningRoom'),

  SagaUpdateVisitorID: new ActionCreator<
    'SagaUpdateVisitorID',
    {
      operationType: 'setToChecked' | 'updateIdAndSetToChecked' | 'setToChecked'
      idUpdateValues: {
        EntityHierarchy: VisitorLogItem['EntityHierarchy']
        ProfileId: VisitorLogItem['ProfileId']
        evtTimeStampProfileID: VisitorLogItem['evtTimeStampProfileID']
        personalIdentificationNr: VISITOR_PROFILE['personalIdentificationNr']
        personalIdentificationNrChecked: VisitorLogItem['personalIdentificationNrChecked']
      }
    }
  >('SagaUpdateVisitorID'),

  SagaUpdateVisitorAssetSerialNumber: new ActionCreator<'SagaUpdateVisitorAssetSerialNumber', any>('SagaUpdateVisitorAssetSerialNumber'),

  SagaVisitorLiveUpdateHandler: new ActionCreator('SagaVisitorLiveUpdateHandler'),
  SagaRefreshVisitorLogSubscriptionCredential: new ActionCreator<'SagaRefreshVisitorLogSubscriptionCredential', string>(
    'SagaRefreshVisitorLogSubscriptionCredential'
  ),
  SagaVisitorLogRetrieveParkingBooking: new ActionCreator<
    'SagaVisitorLogRetrieveParkingBooking',
    { parkingBookingKey: Record<string, unknown> }
  >('SagaVisitorLogRetrieveParkingBooking'),
  SagaUpdateLocalVisitorLogRecord: new ActionCreator<'SagaUpdateVisitorLogRecord', { record: VisitorLogItem }>(
    'SagaUpdateVisitorLogRecord'
  ),
}

function sortLogData(data: VisitorLogItem[]) {
  data.sort((a, b) => (a.evtTimeStamp < b.evtTimeStamp ? 1 : -1))
}

function getVisitorFullName(item: VisitorLogItem) {
  if (item.visitorFullDetails.firstName && item.visitorFullDetails.lastName)
    return `${item.visitorFullDetails.firstName} ${item.visitorFullDetails.lastName}`
  if (item.visitorFullDetails.firstName) return `${item.visitorFullDetails.firstName}`
  if (item.visitorFullDetails.lastName) return `${item.visitorFullDetails.lastName}`
  return `${item.visitorFullDetails.ProfileId}`
}

function* processRetrieveVisitorLogData(action) {
  try {
    yield put(VisitorLogActionCreators.StoreClearAllVisitorsLogData.create())
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ fetching: true })) // set fetching loading
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure

    // const dateRanges = yield select(getVisitorsDateRanges) //todo get date ranges from store
    const beginTime = checkIfTimeExists(action, 'beginTime') ? action.payload.beginTime : moment().startOf('day').valueOf()
    const endTime = checkIfTimeExists(action, 'endTime') ? action.payload.endTime : 9999999999999

    const shouldSubscribeToUpdates = checkIfTimeExists(action, 'shouldSubscribeToUpdates') ? action.payload.shouldSubscribeToUpdates : false
    const getResult = yield call(
      [AWSHelper, AWSHelper.getVisitorlogEvents],
      beginTime,
      endTime,
      entityHierarchy,
      entityHierarchyConfiguration
    )

    if (getResult.key === 'LOG_RETRIEVED') {
      const visitorlogData: VisitorLogItem[] = getResult.logItems
      visitorlogData.forEach((item) => {
        if (!item.evtTimeStampOut) {
          item.evtTimeStampOut = 'still on premises'
        }
        item.visitorFullName = getVisitorFullName(item)
        item.searchString = calculateVisitorSearchString(item)
      })
      sortLogData(visitorlogData)

      yield put(VisitorLogActionCreators.StoreSetVisitorLogShouldSubscribe.create(shouldSubscribeToUpdates))
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(visitorlogData))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ fetching: false })) // set fetching loading
      if (getResult.evacuations && !!getResult.evacuations.length) {
        const hierarchyConfig = yield select(getEntityHierarchyConfiguration)
        const evacuationConfig = cloneDeep(hierarchyConfig.additionalConfigs.evacuation)
        getResult.evacuations.forEach((evacuation) => {
          const currentEvac = evacuationConfig[evacuation.EntityHierarchy]
          if (!currentEvac || (!!currentEvac && currentEvac.lmt < evacuation.lmt)) {
            evacuationConfig[evacuation.EntityHierarchy] = evacuation
          }
        })
        yield put(CredentialsStoreActionCreators.StoreSetConfigEvacuation.create(evacuationConfig))
      }
      if (action.callback) action.callback({ status: 'success' })
    } else throw getResult
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ fetching: false })) // set fetching loading
    if (action.callback) action.callback({ status: 'failed' })
    console.log('got an error in saga')
  }
}

function* processSetVisitorLogDateRange(action) {
  try {
    yield put(VisitorLogActionCreators.StoreSetVisitorsLogDateRange.create(action.payload))
    //todo get data if date ranges are updated
    // const ranges = returnBeginEndAndSubscription(action.payload)
    // yield put(ActionCreators.SagaRetrieveVisitorLogData.create(ranges))
  } catch (e) {
    if (e) {
      console.error(e)
    }
  }
}

function* processSignOutIdentifiedIndividuals(action) {
  try {
    yield put(
      VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
        signout: true, // set local signout loading
        ...(action.payload.loading === 'global' ? { fetching: true } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
      })
    )
    const userData = action.payload.data
    const userlogData = yield call([AWSHelper, AWSHelper.signOutVisitors], userData)
    if (userlogData.key === 'SIGNOUT_SUCCESS') {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedData = userlogData.signedOutVisitors
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        const updateVisitor = updatedData.find((data) => data.evtTimeStampProfileID === logitem.evtTimeStampProfileID)
        if (updateVisitor) {
          logitem.evtTimeStampOut = updateVisitor.evtTimeStampOut
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)

      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(
        VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
          signout: false, // set local signout loading
          ...(action.payload.loading === 'global' ? { fetching: false } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
        })
      )
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(userlogData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSignOutIdentifiedIndividuals, action)
    } else {
      yield put(
        VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
          signout: false, // set local signout loading
          ...(action.payload.loading === 'global' ? { fetching: false } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
        })
      )
      if (action.callback) action.callback({ status: 'failed' })
      console.log(e)
    }
  }
}

function* processSagaRevertSignOut(action) {
  try {
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ signoutrevert: true })) // set signout loading
    const { EntityHierarchy, evtTimeStampProfileID } = action.payload
    const signOutData = yield call([AWSHelper, AWSHelper.revertSignOut], EntityHierarchy, evtTimeStampProfileID)

    if (signOutData.key === 'REVERT_SIGNOUT_SUCCESS') {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const logItemToUpdate = clonedVisitorLogData.find(
        (logitem) => logitem.EntityHierarchy === EntityHierarchy && logitem.evtTimeStampProfileID === evtTimeStampProfileID
      )
      if (logItemToUpdate) {
        logItemToUpdate.evtTimeStampOut = 'still on premises'
        delete logItemToUpdate['evtTZOffsetOut']
        delete logItemToUpdate['evtTimeZoneOut']
        logItemToUpdate.searchString = calculateVisitorSearchString(logItemToUpdate)
      }
      sortLogData(clonedVisitorLogData)

      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(clonedVisitorLogData))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ signoutrevert: true })) // set signout loading
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(signOutData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaRevertSignOut, action)
    } else {
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ signoutrevert: false })) // set delete to loading
      if (action.callback) action.callback({ status: 'failed' })
      console.log(e)
    }
  }
}

function* processDeleteIdentifiedIndividuals(action) {
  try {
    yield put(
      VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
        delete: true, // set local delete state
        ...(action.payload.loading === 'global' ? { fetching: true } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
      })
    )
    const logEntries: VisitorLogItem[] = action.payload.data
    const userlogData = yield call([AWSHelper, AWSHelper.deleteUsers], logEntries)
    if (userlogData.key === 'ENTRIES_DELETED') {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const delUsersMap = new Map()
      logEntries.forEach((deletedUser) => {
        delUsersMap.set(deletedUser.evtTimeStampProfileID, deletedUser)
      })
      const updatedLogs = clonedVisitorLogData.filter((logitem) => !delUsersMap.get(logitem.evtTimeStampProfileID))
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(
        VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
          delete: false, // set local delete state
          ...(action.payload.loading === 'global' ? { fetching: false } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
        })
      )
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(userlogData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processDeleteIdentifiedIndividuals, action)
    } else {
      yield put(
        VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({
          delete: false, // set local delete state
          ...(action.payload.loading === 'global' ? { fetching: false } : {}), //only sets log loading state if signout is triggered from toolbar (multiple signouts)
        })
      )
      if (action.callback) action.callback({ status: 'failed' })
      console.log(e)
    }
  }
}

function* processUpdateVisitorCustomFieldValue(action: typeof ActionCreators.SagaUpdateVisitorCustomFieldValue) {
  try {
    const actionData = action.payload

    const customFieldUpdateData = yield call([AWSHelper, AWSHelper.updateVisitorCustomFieldValue], actionData)
    if (customFieldUpdateData.key === 'CUSTOM_FIELD_VALUE_UPDATED') {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (logitem.evtTimeStampProfileID === actionData.evtTimeStampProfileID) {
          if (logitem.checkInFieldValues) {
            logitem.checkInFieldDataKeys = { ...(logitem.checkInFieldDataKeys ? logitem.checkInFieldDataKeys : {}) }
            logitem.checkInFieldValues[actionData.fieldId] = actionData.updatedValue
          }
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(customFieldUpdateData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processUpdateVisitorCustomFieldValue, action)
    } else {
      if (action.callback) action.callback({ status: 'failed', message: e })
      console.log(e)
    }
  }
}

function* processLinkVisitorBadge(action) {
  const badgeData: VisitorLogItem = action.payload
  let currentBadgeNumber: string = ''
  try {
    const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
    const currentLogItem = logData.find(
      (logitem) =>
        logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID
    )
    currentBadgeNumber = currentLogItem.badgeNumber //get the current badge number in case we need to roll the assignment back
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ badgelinking: true })) // set badge linking loading
    const badgeLoggedData = yield call([AWSHelper, AWSHelper.captureVisitorBadgeNumber], badgeData)
    if (badgeLoggedData.key === 'BADGE_NR_UPDATED') {
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID) {
          logitem.badgeNumber = badgeData.badgeNumber
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ badgelinking: false })) // set badge linking loading
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(badgeLoggedData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processLinkVisitorBadge, action)
    } else {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID) {
          if (currentBadgeNumber) {
            logitem.badgeNumber = currentBadgeNumber
          } else {
            delete logitem.badgeNumber
          }
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ badgelinking: false })) // set  badge linking loading
      if (action.callback) action.callback({ status: 'failed', message: e })
      console.log(e)
    }
  }
}

function* processSetInductionCompleteInScreeningRoom(action) {
  try {
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ inductionCompletion: true })) // set induction loading
    const inductionPayload = action.payload
    const inductionData = {
      EntityHierarchy: inductionPayload.EntityHierarchy,
      inductionType: INDUCTION_TYPES_ENUM.SCREENING_ROOM,
      version: inductionPayload.channels.screeningRoom.version,
      ProfileId: inductionPayload.ProfileId,
      evtTimeStampProfileID: inductionPayload.evtTimeStampProfileID,
    }
    const inductionLoggedData = yield call([AWSHelper, AWSHelper.setInductionCompleteInScreeningRoom], inductionData)
    if (inductionLoggedData.key === 'INDUCTION_UPDATED') {
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (logitem.evtTimeStampProfileID === action.payload.evtTimeStampProfileID) {
          logitem.inductionData = {
            inductionType: inductionData.inductionType,
            version: inductionData.version,
            eventTiming: inductionLoggedData.eventTiming,
          }
          logitem.visitorFullDetails.inductionData = {
            inductionType: inductionData.inductionType,
            version: inductionData.version,
            eventTiming: inductionLoggedData.eventTiming,
          }
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)

      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ inductionCompletion: false })) // set induction loading
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(inductionLoggedData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSetInductionCompleteInScreeningRoom, action)
    } else {
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ inductionCompletion: false })) // set induction loading
      if (action.callback) action.callback({ status: 'failed', message: e || '' })
      console.log(e)
    }
  }
}

function* processVisitorLiveUpdateHandler(action) {
  try {
    const eventPayload = action.payload
    const liveUpdateEarliestTimestamp = yield select(getLiveUpdateEarliestTimestamp)
    if (eventPayload.messageReceivedTimeStamp > liveUpdateEarliestTimestamp) {
      const selectedDateRange = yield select(getVisitorsDateRanges)
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const availableHierarchies = new Map()
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          availableHierarchies.set(prop.hierarchyStructure, true)
          if (prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }
      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
        addRecursive(entityHierarchyConfiguration.hierarchy.deepStructure)
      } else {
        addRecursive([entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure]])
      }
      const deletions = new Map()
      const changes = new Map()
      const newEntries = new Map()
      eventPayload.events.forEach((logEvent) => {
        const evtTimeStamp = parseInt(logEvent.evtTimeStampProfileID.split('#')[0])
        if (selectedDateRange[0] <= evtTimeStamp && selectedDateRange[1] >= evtTimeStamp) {
          //only process changes contained in current selection
          if (logEvent.eventType === 'REMOVE' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
            deletions.set(logEvent.evtTimeStampProfileID, logEvent)
          } else if (logEvent.eventType === 'MODIFY' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
            changes.set(logEvent.evtTimeStampProfileID, logEvent)
          } else if (logEvent.eventType === 'INSERT' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
            newEntries.set(logEvent.evtTimeStampProfileID, logEvent)
          }
        }
      })
      const itemsToRetrieve: any[] = []
      let retrievedItems: any[] = []
      changes.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
      })
      newEntries.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
      })
      if (itemsToRetrieve.length > 0) {
        yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
        yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

        const getResult = yield call([AWSHelper, AWSHelper.getSelectedVisitorlogs], itemsToRetrieve)
        if (getResult.key !== 'LOG_RETRIEVED') {
          throw getResult
        }
        retrievedItems = getResult.logItems
      }
      const currentLogData = yield select(getVisitorsLogData)
      const newLogData = cloneDeep(currentLogData)
      deletions.forEach((value) => {
        const indexDelete = findIndex(newLogData, {
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
        if (indexDelete !== -1) {
          newLogData.splice(indexDelete, 1)
        }
      })
      if (retrievedItems) {
        retrievedItems.forEach((retrievedItem) => {
          const changedEntry = changes.get(retrievedItem.evtTimeStampProfileID)
          const newEntry = newEntries.get(retrievedItem.evtTimeStampProfileID)
          retrievedItem.visitorFullName = getVisitorFullName(retrievedItem)
          if (changedEntry) {
            const indexCurrent = findIndex(newLogData, {
              evtTimeStampProfileID: retrievedItem.evtTimeStampProfileID,
            })
            if (indexCurrent !== -1) {
              retrievedItem.searchString = calculateVisitorSearchString(retrievedItem)
              newLogData.splice(indexCurrent, 1, retrievedItem)
            }
          } else if (newEntry) {
            const indexCurrent = findIndex(newLogData, {
              evtTimeStampProfileID: retrievedItem.evtTimeStampProfileID,
            })
            if (indexCurrent === -1) {
              retrievedItem.searchString = calculateVisitorSearchString(retrievedItem)
              newLogData.push(retrievedItem)
            }
          }
        })
      }
      newLogData.forEach((item) => {
        if (!item.evtTimeStampOut) {
          item.evtTimeStampOut = 'still on premises'
        }
      })
      sortLogData(newLogData)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(newLogData))
      // yield put(VisitorLogActionCreators.StoreSetVisitorLogShouldSubscribe.create(true))
      // console.log("====PUBSUB UPDATE====\nSAGA: updated logs: ", newLogData, "\n====PUBSUB UPDATE====")
    }
  } catch (e) {
    console.log(e)
  }
}

function* processRefreshVisitorLogSubscriptionCredential(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getVisitorSubscriptionCredential)
    const clientId = action.payload

    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) {
        //allow for 5 minute clock skew
        shouldRetrieveNewCredentials = false
      }
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: any[] = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `visitorEvents/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })
      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetVisitorSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else {
        yield put(CredentialsStoreActionCreators.StoreSetVisitorSubscriptionCredential.create(undefined))
      }
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetVisitorSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
  } catch (e) {
    if (e) {
      console.error(e)
    }
    yield put(CredentialsStoreActionCreators.StoreSetVisitorSubscriptionCredential.create(undefined))
  }
}

function* processSagaUpdateVisitorID(action) {
  try {
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ idUpdates: true })) // set id update loading
    const idDataToUpdate = action.payload
    const idUpdatedData = yield call([AWSHelper, AWSHelper.updatePersonalIdentificationNr], idDataToUpdate)
    if (idUpdatedData.key === 'ID_CHECKED') {
      const idData = idDataToUpdate.idUpdateValues
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (logitem.EntityHierarchy === idData.EntityHierarchy && logitem.evtTimeStampProfileID === idData.evtTimeStampProfileID) {
          if (logitem.visitorFullDetails) logitem.visitorFullDetails.personalIdentificationNr = idData.personalIdentificationNr
          logitem.personalIdentificationNrChecked = idData.personalIdentificationNrChecked
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ idUpdates: false })) // set id update loading
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(idUpdatedData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUpdateVisitorID, action)
    } else {
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ idUpdates: false })) // set id update loading
      if (action.callback) action.callback({ status: 'failed' })
      console.log(e)
    }
  }
}

function* processSagaUpdateVisitorAssetSerialNumber(action) {
  try {
    yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ assetSerialNumberUpdates: true })) // set id update loading
    const assetSerialNumberUpdatePayload = action.payload
    const assetSerialNumberUpdateData = yield call([AWSHelper, AWSHelper.updateAssetSerialNumber], assetSerialNumberUpdatePayload)
    if (assetSerialNumberUpdateData.key === 'ASSET_SERIAL_NUMBER_UPDATED') {
      const updatedItem = assetSerialNumberUpdatePayload.assetSerialNumberUpdateValues
      const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
      const clonedVisitorLogData = cloneDeep(logData)
      const updatedLogs = clonedVisitorLogData.map((logitem) => {
        if (
          logitem.EntityHierarchy === updatedItem.EntityHierarchy &&
          logitem.evtTimeStampProfileID === updatedItem.evtTimeStampProfileID
        ) {
          logitem.assetSerialNumber = updatedItem.assetSerialNumber
          logitem.searchString = calculateVisitorSearchString(logitem)
        }
        return logitem
      })
      sortLogData(updatedLogs)
      yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(updatedLogs))
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ assetSerialNumberUpdates: false })) // set id update loading
      if (action.callback) action.callback({ status: 'success' })
    } else {
      throw new Error(assetSerialNumberUpdateData.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaUpdateVisitorAssetSerialNumber, action)
    } else {
      yield put(VisitorLogActionCreators.StoreUpdateVisitorLogStates.create({ assetSerialNumberUpdates: false })) // set id update loading
      if (action.callback) action.callback({ status: 'failed', message: e })
      console.log(e)
    }
  }
}

function* processSagaVisitorLogRetrieveParkingBooking(action) {
  try {
    const parkingBookingResult = yield call([AWSHelper, AWSHelper.retrieveParkingBooking], action.payload.parkingBookingKey)
    if (parkingBookingResult.Item) {
      if (action.callback) action.callback({ status: 'booking_found', bookingItem: parkingBookingResult.Item })
    } else {
      if (action.callback) action.callback({ status: 'booking_notfound' })
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaVisitorLogRetrieveParkingBooking, action)
    } else {
      if (action.callback) action.callback({ status: 'failed', message: e })
    }
  }
}

function* processUpdateLocalVisitorLogRecord(action: typeof ActionCreators.SagaUpdateLocalVisitorLogRecord) {
  try {
    const { record } = action.payload

    const logData: VisitorLogItem[] = yield select(getVisitorsLogData)
    const clonedVisitorLogData = cloneDeep(logData).map((logItem) => {
      if (logItem.evtTimeStampProfileID === record.evtTimeStampProfileID) {
        logItem = record
        logItem.searchString = calculateVisitorSearchString(logItem)
      }
      return logItem
    })

    sortLogData(clonedVisitorLogData)

    yield put(VisitorLogActionCreators.StoreSetVisitorLogData.create(clonedVisitorLogData))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (action.callback) action.callback({ status: 'failed' })
    console.log(e)
  }
}

function* watchVisitorLiveUpdateChannel() {
  const SagaVisitorLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaVisitorLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaVisitorLiveUpdateHandlerChannel)
    yield call(processVisitorLiveUpdateHandler, action)
  }
}

// Saga triggers
function* watchVisitorsLogSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveVisitorLogData.type, processRetrieveVisitorLogData)
  yield takeLeading(ActionCreators.SagaSetVisitorLogDateRange.type, processSetVisitorLogDateRange)
  yield takeEvery(ActionCreators.SagaSignOutIdentifiedIndividuals.type, processSignOutIdentifiedIndividuals)
  yield takeEvery(ActionCreators.SagaRevertSignOut.type, processSagaRevertSignOut)
  yield takeEvery(ActionCreators.SagaDeleteIdentifiedIndividuals.type, processDeleteIdentifiedIndividuals)
  yield takeEvery(ActionCreators.SagaUpdateVisitorCustomFieldValue.type, processUpdateVisitorCustomFieldValue)
  yield takeEvery(ActionCreators.SagaLinkVisitorBadge.type, processLinkVisitorBadge)
  yield takeEvery(ActionCreators.SagaVisitorSetInductionCompleteInScreeningRoom.type, processSetInductionCompleteInScreeningRoom)
  yield takeLeading(ActionCreators.SagaRefreshVisitorLogSubscriptionCredential.type, processRefreshVisitorLogSubscriptionCredential)
  yield takeEvery(ActionCreators.SagaUpdateVisitorID.type, processSagaUpdateVisitorID)
  yield takeEvery(ActionCreators.SagaUpdateVisitorAssetSerialNumber.type, processSagaUpdateVisitorAssetSerialNumber)
  yield takeEvery(ActionCreators.SagaVisitorLogRetrieveParkingBooking.type, processSagaVisitorLogRetrieveParkingBooking)
  yield takeEvery(ActionCreators.SagaUpdateLocalVisitorLogRecord.type, processUpdateLocalVisitorLogRecord)
  yield null
}

// Saga hooks
export default function* VisitorsLogSagas() {
  yield all([fork(watchVisitorsLogSagas), fork(watchVisitorLiveUpdateChannel)])
}

import { cloneDeep, findIndex, orderBy } from 'lodash'
import moment from 'moment'
import { actionChannel, all, call, fork, put, select, take, takeLeading } from 'redux-saga/effects'
import API from 'src/api'
// import { ActionCreators as DashboardActionCreators } from 'src/redux/store/dashboard'
import { ActionCreators as AuthenticationActionCreators } from 'src/redux/sagas/authentication'
import { ActionCreators as CredentialsStoreActionCreators, getEntityHierarchyConfiguration } from 'src/redux/store/credentials'
import {
  ActionCreators as StoreActionCreators,
  EmployeeLogSelectors as StoreSelectors,
  getEmployeeLogDateRanges,
} from 'src/redux/store/employees/employee-log-store'
import { EmployeeDetails } from 'src/redux/store/employees/types/directory'
import { EmployeeLogItem } from 'src/redux/store/employees/types/employee-log'
import { getWorkerSubscriptionCredential } from 'src/redux/store/selectors'
import AccessManager from 'src/utils/AccessManager'
import ActionCreator from '../saga-action-creator'
import {
  generateEmployeeLogPairSearchString,
  getSelectedEmployeeLogs,
  timePairedEmployeeLogEvents,
  updateEmployeeLogItem,
} from './helpers/employee-log'
import { getHierarchicalEntityFlagValue } from 'src/utils/entityFlagProcessor'
import { getLiveUpdateEarliestTimestamp } from 'src/redux/store/dashboard/globalevents'
import { UserImage } from 'src/typings/kenai/user-image'
import { EntityHierarchyConfig } from 'src/typings/kenai/configuration/entity-hierarchy'

type TargetEmployee = Pick<EmployeeLogItem, 'EntityHierarchy' | 'evtTimeStampProfileID'>

interface TargetEmployeeExtended extends Pick<EmployeeLogItem, 'EntityHierarchy' | 'evtTimeStampProfileID'> {
  ProfileId: EmployeeDetails['ProfileId']
  s3ImageKey?: UserImage['s3ImageKey']
}

interface LiveUpdateHandlerEvent extends Pick<EmployeeLogItem, 'EntityHierarchy' | 'evtTimeStampProfileID'> {
  eventType: 'REMOVE' | 'MODIFY' | 'INSERT'
}

interface EmployeeLinkBadePayload {
  EntityHierarchy: string
  evtTimeStampProfileID: string
  badgeNumber: string
}

// Action Creators
export const ActionCreators = {
  SagaRetrieveEmployeeLogData: new ActionCreator<
    'SagaRetrieveEmployeeLogData',
    { beginTime: number; endTime: number; shouldSubscribeToUpdates?: boolean; stronglyConsistent?: boolean }
  >('SagaRetrieveEmployeeLogData'),
  SagaRefreshEmployeeLogSubscriptionCredential: new ActionCreator<'SagaRefreshEmployeeLogSubscriptionCredential', string>(
    'SagaRefreshEmployeeLogSubscriptionCredential'
  ),
  SagaRejectEmployeeLogImage: new ActionCreator<'SagaRejectEmployeeLogImage', TargetEmployee>('SagaRejectEmployeeLogImage'),
  SagaAcceptEmployeeLogImage: new ActionCreator<'SagaAcceptEmployeeLogImage', TargetEmployee>('SagaAcceptEmployeeLogImage'),
  SagaRemoveEmployeeReferenceImage: new ActionCreator<'SagaRemoveEmployeeReferenceImage', TargetEmployeeExtended>(
    'SagaRemoveEmployeeReferenceImage'
  ),
  SagaEmployeeLiveUpdateHandler: new ActionCreator<
    'SagaEmployeeLiveUpdateHandler',
    { messageReceivedTimeStamp: number; events: LiveUpdateHandlerEvent[] }
  >('SagaEmployeeLiveUpdateHandler'),
  SagaEmployeeCorrectionConvertClockInToClockOut: new ActionCreator<'SagaEmployeeCorrectionConvertClockInToClockOut', any>(
    'SagaEmployeeCorrectionConvertClockInToClockOut'
  ),
  SagaEmployeeCorrectionAdjustClockInTime: new ActionCreator('SagaEmployeeCorrectionAdjustClockInTime'),
  // SagaEmployeeCorrectionDeleteClockIn: new ActionCreator('SagaEmployeeCorrectionDeleteClockIn'),
  SagaEmployeeCorrectionAdjustClockOutTime: new ActionCreator('SagaEmployeeCorrectionAdjustClockOutTime'),
  // SagaEmployeeCorrectionDeleteClockOut: new ActionCreator('SagaEmployeeCorrectionDeleteClockOut'),
  SagaEmployeeCorrectionCreateClockOut: new ActionCreator('SagaEmployeeCorrectionCreateClockOut'),
  SagaEmployeeCorrectionCreateNewTimePair: new ActionCreator('SagaEmployeeCorrectionCreateNewTimePair'),
  SagaEmployeeCorrectionAddComment: new ActionCreator('SagaEmployeeCorrectionAddComment'),
  SagaEmployeeLinkBadge: new ActionCreator<'SagaEmployeeLinkBadge', EmployeeLinkBadePayload>('SagaEmployeeLinkBadge'),
}

const AWSHelper = new API.AWSHelpers()

export function* retrieveEmployeeLogData(
  entityHierarchyConfiguration: EntityHierarchyConfig,
  payload: typeof ActionCreators.SagaRetrieveEmployeeLogData.payload
) {
  const entityHierarchy = AccessManager.selectedHierarchy.hierarchyStructure
  const beginTime = payload.beginTime || moment().startOf('day').valueOf()
  const endTime = payload.endTime || 9999999999999
  const shouldSubscribeToUpdates = payload.shouldSubscribeToUpdates || false
  const hasClockOut = getHierarchicalEntityFlagValue(
    entityHierarchyConfiguration,
    `availableIdentities[@flowType=1].flowVariables.ACTIVE`,
    AccessManager.selectedHierarchy.hierarchyStructure
  )
  const beginTimeLessOneDay = hasClockOut ? beginTime - 1000 * 60 * 60 * 24 : beginTime //selection range is selection date - 24 hours
  const stronglyConsistent = payload.stronglyConsistent
  const employeeEntries: EmployeeLogItem[] = yield call(
    [AWSHelper, AWSHelper.employeeApi.employeeLog.getEmployeeLogEvents],
    beginTimeLessOneDay,
    endTime,
    entityHierarchy,
    entityHierarchyConfiguration,
    stronglyConsistent
  )

  const selectedLogs = getSelectedEmployeeLogs(employeeEntries, beginTime)

  // TODO - refactor this so that it is handled in the backend
  const timePairs = orderBy(
    generateEmployeeLogPairSearchString(timePairedEmployeeLogEvents(selectedLogs), entityHierarchyConfiguration),
    ['referenceTimeStamp'],
    ['desc']
  )

  return {
    employeeEntries,
    timePairs,
    shouldSubscribeToUpdates,
  }
}

function* processRetrieveEmployeeLogData(action: typeof ActionCreators.SagaRetrieveEmployeeLogData) {
  //SagaRetrieveEmployeeLogData
  try {
    yield put(StoreActionCreators.StoreSetEmployeeLogLoading.create(true))
    // yield put(StoreActionCreators.StoreClearAllEmployeeLogData.create())
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)

    const { employeeEntries, timePairs, shouldSubscribeToUpdates } = yield retrieveEmployeeLogData(
      entityHierarchyConfiguration,
      action.payload
    )

    yield put(StoreActionCreators.StoreSetEmployeeLogData.create(employeeEntries))
    yield put(
      StoreActionCreators.StoreSetEmployeeLogEventPairData.create({
        data: timePairs,
        shouldSubscribeToUpdates: shouldSubscribeToUpdates ? true : false,
      })
    )
    yield put(StoreActionCreators.StoreSetEmployeeLogLoading.create(false))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.error(e)
    yield put(StoreActionCreators.StoreSetEmployeeLogLoading.create(false))
    if (action.callback)
      action.callback({ status: 'failed', message: 'We had an issue retrieving the employee logs. Please try again or contact support.' })
  }
}

function* processRefreshEmployeeLogSubscriptionCredential(action: typeof ActionCreators.SagaRefreshEmployeeLogSubscriptionCredential) {
  //SagaRefreshEmployeeLogSubscriptionCredential
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentSubscriptionCredential = yield select(getWorkerSubscriptionCredential)
    const clientId = action.payload
    let shouldRetrieveNewCredentials = true
    if (currentSubscriptionCredential && currentSubscriptionCredential.Expiration) {
      const expiry = moment(currentSubscriptionCredential.Expiration)
      const now = moment()
      const diff = expiry.diff(now)
      if (diff / 1000 > 60 * 5) shouldRetrieveNewCredentials = false //allow for 5 minute clock skew
    }
    if (shouldRetrieveNewCredentials) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const topics: any[] = []
      entityHierarchyConfiguration.hierarchy.deepStructure.forEach((hierarchy) => {
        //just subscribe to the root
        const topicWithOutTrailingSlash = `workerEvents/${hierarchy.hierarchyStructure.replace(/#/g, '/')}`
        if (topics.length < 8) {
          //never subscribe to more than 8
          topics.push(topicWithOutTrailingSlash)
        }
      })

      const subscriptionCredential = yield call([AWSHelper, AWSHelper.retrieveSubscribeCredential], topics, clientId)
      if (subscriptionCredential.key === 'CREDENTIAL_RETRIEVED') {
        yield put(
          CredentialsStoreActionCreators.StoreSetWorkerSubscriptionCredential.create({
            ...subscriptionCredential.credential,
            ts: Date.now(),
          })
        )
      } else yield put(CredentialsStoreActionCreators.StoreSetWorkerSubscriptionCredential.create(undefined))
    } else if (!shouldRetrieveNewCredentials && currentSubscriptionCredential) {
      yield put(
        CredentialsStoreActionCreators.StoreSetWorkerSubscriptionCredential.create({
          ...currentSubscriptionCredential,
          ts: Date.now(),
        })
      ) //triggers gDSfP in subscription component
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.error(e)
    yield put(CredentialsStoreActionCreators.StoreSetWorkerSubscriptionCredential.create(undefined))
    if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'failed to set workers subscription credentials' })
  }
}

function* processRejectEmployeeLogImage(action) {
  //SagaRejectEmployeeLogImage
  try {
    const { EntityHierarchy, evtTimeStampProfileID } = action.payload
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const logItem = {
      EntityHierarchy,
      evtTimeStampProfileID,
    }
    const result = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.rejectEmployeeLogImage], [logItem])
    if (result.key !== 'REJECT_SUCCESS') throw new Error('could not reject employee image')
    const updateItems: EmployeeLogItem[] = result.rejectedLogs
    const oldEventPairs: ReturnType<typeof StoreSelectors.getEmployeeEventPairData> = yield select(StoreSelectors.getEmployeeEventPairData)
    const updatedEventPairs = generateEmployeeLogPairSearchString(
      updateEmployeeLogItem(oldEventPairs, updateItems),
      entityHierarchyConfiguration
    )
    yield put(StoreActionCreators.StoreSetEmployeeLogEventPairData.create({ data: updatedEventPairs }))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processRejectEmployeeLogImage, action)
    } else {
      if (e) console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'could not reject the image' })
    }
  }
}

function* processAcceptEmployeeLogImage(action) {
  //SagaAcceptEmployeeLogImage
  try {
    const { EntityHierarchy, evtTimeStampProfileID } = action.payload
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const dateToReturn: ReturnType<typeof StoreSelectors.getEmployeeLogDateRanges> = yield select(StoreSelectors.getEmployeeLogDateRanges)
    const hasClockOut = getHierarchicalEntityFlagValue(
      entityHierarchyConfiguration,
      `availableIdentities[@flowType=1].flowVariables.ACTIVE`,
      AccessManager.selectedHierarchy.hierarchyStructure
    )
    const adjustedDateToReturn = {
      beginTime: hasClockOut ? dateToReturn[0] - 1000 * 60 * 60 * 24 : dateToReturn[0],
      endTime: dateToReturn[1],
    }
    const result = yield call(
      [AWSHelper, AWSHelper.employeeApi.employeeLog.acceptEmployeeLogImage],
      EntityHierarchy,
      evtTimeStampProfileID,
      adjustedDateToReturn
    )
    if (result.key !== 'IMAGE_ACCEPTED') throw new Error('Could not accept image on API')
    const updateItems: EmployeeLogItem[] = result.returnLogs
    const oldEventPairs: ReturnType<typeof StoreSelectors.getEmployeeEventPairData> = yield select(StoreSelectors.getEmployeeEventPairData)
    const updatedEventPairs = generateEmployeeLogPairSearchString(
      updateEmployeeLogItem(oldEventPairs, updateItems),
      entityHierarchyConfiguration
    )
    yield put(StoreActionCreators.StoreSetEmployeeLogEventPairData.create({ data: updatedEventPairs }))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processAcceptEmployeeLogImage, action)
    } else {
      if (e) console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'could not accept the image' })
    }
  }
}

function* processRemoveEmployeeReferenceImage(action: typeof ActionCreators.SagaRemoveEmployeeReferenceImage) {
  //SagaRemoveEmployeeReferenceImage
  try {
    const { EntityHierarchy, evtTimeStampProfileID, ProfileId, s3ImageKey } = action.payload
    const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
    const dateToReturn: ReturnType<typeof StoreSelectors.getEmployeeLogDateRanges> = yield select(StoreSelectors.getEmployeeLogDateRanges)
    const hasClockOut = getHierarchicalEntityFlagValue(
      entityHierarchyConfiguration,
      `availableIdentities[@flowType=1].flowVariables.ACTIVE`,
      AccessManager.selectedHierarchy.hierarchyStructure
    )
    const adjustedDateToReturn = {
      beginTime: hasClockOut ? dateToReturn[0] - 1000 * 60 * 60 * 24 : dateToReturn[0],
      endTime: dateToReturn[1],
    }
    const result = yield call(
      [AWSHelper, AWSHelper.employeeApi.employeeLog.removeEmployeeReferenceImage],
      EntityHierarchy,
      evtTimeStampProfileID,
      ProfileId,
      s3ImageKey,
      adjustedDateToReturn
    )
    if (result.key !== 'REF_IMAGE_REMOVED') throw new Error('employee reference image could not be removed')
    const updateItems: EmployeeLogItem[] = result.returnLogs
    const oldEventPairs: ReturnType<typeof StoreSelectors.getEmployeeEventPairData> = yield select(StoreSelectors.getEmployeeEventPairData)
    const updatedEventPairs = generateEmployeeLogPairSearchString(
      updateEmployeeLogItem(oldEventPairs, updateItems),
      entityHierarchyConfiguration
    )
    yield put(StoreActionCreators.StoreSetEmployeeLogEventPairData.create({ data: updatedEventPairs }))
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processRemoveEmployeeReferenceImage, action)
    } else {
      if (e) console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'could not accept the image' })
    }
  }
}

function* processEmployeeLiveUpdateHandler(action: typeof ActionCreators.SagaEmployeeLiveUpdateHandler) {
  try {
    const eventPayload = action.payload
    const liveUpdateEarliestTimestamp = yield select(getLiveUpdateEarliestTimestamp)
    if (eventPayload.messageReceivedTimeStamp > liveUpdateEarliestTimestamp) {
      const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
      const availableHierarchies = new Map()
      const addRecursive = (additionalChildren) => {
        for (const prop of additionalChildren) {
          availableHierarchies.set(prop.hierarchyStructure, true)
          if (prop.children.length > 0) {
            addRecursive(prop.children)
          }
        }
      }
      if (AccessManager.selectedHierarchy.hierarchyStructure === 'ALL') {
        addRecursive(entityHierarchyConfiguration.hierarchy.deepStructure)
      } else {
        addRecursive([entityHierarchyConfiguration.hierarchy.flatStructure[AccessManager.selectedHierarchy.hierarchyStructure]])
      }

      const deletions = new Map()
      const changes = new Map()
      const newEntries = new Map()
      eventPayload.events.forEach((logEvent) => {
        if (logEvent.eventType === 'REMOVE' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          deletions.set(logEvent.evtTimeStampProfileID, logEvent)
        } else if (logEvent.eventType === 'MODIFY' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          changes.set(logEvent.evtTimeStampProfileID, logEvent)
        } else if (logEvent.eventType === 'INSERT' && availableHierarchies.get(logEvent.EntityHierarchy) === true) {
          newEntries.set(logEvent.evtTimeStampProfileID, logEvent)
        }
      })

      const itemsToRetrieve: any[] = []
      let retrievedItems: any[] = []
      changes.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
      })
      newEntries.forEach((value) => {
        itemsToRetrieve.push({
          EntityHierarchy: value.EntityHierarchy,
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
      })
      if (itemsToRetrieve.length > 0) {
        yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
        yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)

        retrievedItems = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.getSelectedEmployeeLogs], itemsToRetrieve)
      }

      const currentLogData = yield select(StoreSelectors.getEmployeeLogData)
      const employeeLogData = cloneDeep(currentLogData)

      deletions.forEach((value) => {
        const indexDelete = findIndex(employeeLogData, {
          evtTimeStampProfileID: value.evtTimeStampProfileID,
        })
        if (indexDelete !== -1) {
          employeeLogData.splice(indexDelete, 1)
        }
      })

      retrievedItems.forEach((retrievedItem) => {
        const changedEntry = changes.get(retrievedItem.evtTimeStampProfileID)
        const newEntry = newEntries.get(retrievedItem.evtTimeStampProfileID)
        if (changedEntry) {
          const indexCurrent = findIndex(employeeLogData, {
            evtTimeStampProfileID: retrievedItem.evtTimeStampProfileID,
          })
          if (indexCurrent !== -1) {
            employeeLogData.splice(indexCurrent, 1, retrievedItem)
          }
        } else if (newEntry) {
          const indexCurrent = findIndex(employeeLogData, {
            evtTimeStampProfileID: retrievedItem.evtTimeStampProfileID,
          })
          if (indexCurrent === -1) {
            employeeLogData.push(retrievedItem)
          }
        }
      })

      const [selectedBeginTime] = yield select(getEmployeeLogDateRanges)
      const beginTime = selectedBeginTime || moment().startOf('day').valueOf()
      const selectedLogs = getSelectedEmployeeLogs(employeeLogData, beginTime)

      const timePairs = orderBy(
        generateEmployeeLogPairSearchString(timePairedEmployeeLogEvents(selectedLogs), entityHierarchyConfiguration),
        ['referenceTimeStamp'],
        ['desc']
      )
      yield put(
        StoreActionCreators.StoreSetEmployeeLogEventPairData.create({
          data: timePairs,
          shouldSubscribeToUpdates: true,
        })
      )
      yield put(StoreActionCreators.StoreSetEmployeeLogData.create(employeeLogData))
    }
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e) console.log(e)
    if (action.callback) action.callback({ status: 'failed', message: e?.message ?? 'could live update employee log' })
  }
}

function* processSagaEmployeeCorrectionConvertClockInToClockOut(
  action: typeof ActionCreators.SagaEmployeeCorrectionConvertClockInToClockOut
) {
  //SagaEmployeeCorrectionConvertClockInToClockOut
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    console.log('[ TODO ]  type correction data')
    const correctionData = action.payload
    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionConvertClockInToClockOut], correctionData)
    if (!correctionResult) throw new Error('could not apply the clock-clock to clock-out correction')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionConvertClockInToClockOut, action)
    } else {
      if (e) console.error(e)
      if (action.callback)
        action.callback({ status: 'failed', message: e?.message ?? 'could not apply the clock-clock to clock-out correction' })
    }
  }
}

function* processSagaEmployeeCorrectionAdjustClockInTime(action: typeof ActionCreators.SagaEmployeeCorrectionAdjustClockInTime) {
  //SagaEmployeeCorrectionAdjustClockInTime
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    console.log('[TODO]  type correction data')
    const correctionData = action.payload

    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionAdjustClockInTime], correctionData)

    if (!correctionResult.transactionResult) throw new Error('could not apply the clock-in time adjustment')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionAdjustClockInTime, action)
    } else if (e.code === 'TransactionCanceledException' && e.message.indexOf('ConditionalCheckFailed') > -1) {
      if (!action.callback) console.error(e)
      if (action.callback)
        action.callback({
          status: 'failed',
          message: 'A clock in/out for the specified dates/times already exists - please correct your entries',
        })
    } else {
      if (e) console.error(e)
      if (action.callback)
        action.callback({ status: 'failed', message: 'We had an issue applying the correction. Please try again or contact support.' })
    }
  }
}

// function* processSagaEmployeeCorrectionDeleteClockIn(action) {
// //SagaEmployeeCorrectionDeleteClockIn
// }

function* processSagaEmployeeCorrectionAdjustClockOutTime(action) {
  //SagaEmployeeCorrectionAdjustClockOutTime
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    console.log('[ TODO ]  define correction data type')
    const correctionData = action.payload
    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionAdjustClockOutTime], correctionData)
    if (!correctionResult.transactionResult) throw new Error('could not apply the clock-out time adjustment')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionAdjustClockOutTime, action)
    } else if (e.code === 'TransactionCanceledException' && e.message.indexOf('ConditionalCheckFailed') > -1) {
      if (action.callback)
        action.callback({
          status: 'failed',
          message: 'A clock in/out for the specified dates/times already exists - please correct your entries',
        })
    } else {
      if (e) console.error(e)
      if (action.callback)
        action.callback({ status: 'failed', message: 'We had an issue applying the correction. Please try again or contact support.' })
    }
  }
}

// function* processSagaEmployeeCorrectionDeleteClockOut(action) {
// //SagaEmployeeCorrectionDeleteClockOut
// }

function* processSagaEmployeeCorrectionCreateClockOut(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    console.log('[ TODO ]  define correction data type')
    const correctionData = action.payload
    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionCreateClockOut], correctionData)
    if (!correctionResult.transactionResult) throw new Error('failed to create clock-out')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionAdjustClockOutTime, action)
    } else if (e.code === 'TransactionCanceledException' && e.message.indexOf('ConditionalCheckFailed') > -1) {
      if (action.callback)
        action.callback({
          status: 'failed',
          message: 'A clock in/out for the specified dates/times already exists - please correct your entries',
        })
    } else {
      if (e) console.error(e)
      if (action.callback)
        action.callback({ status: 'failed', message: 'We had an issue applying the correction. Please try again or contact support.' })
    }
  }
}

function* processSagaEmployeeCorrectionCreateNewTimePair(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    console.log('[ TODO ]  define correction data types')
    const correctionData = action.payload
    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionCreateNewTimePair], correctionData)
    if (!correctionResult.transactionResult) throw new Error('failed to create new time pair')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionAdjustClockOutTime, action)
    } else if (e.code === 'TransactionCanceledException' && e.message.indexOf('ConditionalCheckFailed') > -1) {
      if (action.callback) {
        action.callback({
          status: 'failed',
          message: 'A clock in/out for the specified dates/times already exists - please correct your entries',
        })
      }
    } else {
      if (e) console.error(e)
      if (action.callback) {
        action.callback({ status: 'failed', message: 'We had an issue applying the correction. Please try again or contact support.' })
      }
    }
  }
}

function* processSagaEmployeeCorrectionAddComment(action) {
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const correctionData = action.payload
    const correctionResult = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.correctionAddComment], correctionData)
    if (!correctionResult) throw new Error('failed to add comment correction to employee')
    if (action.callback) action.callback({ status: 'success' })
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeCorrectionAddComment, action)
    } else {
      if (e) console.error(e)
      if (action.callback) {
        action.callback({ status: 'failed', message: 'We had an issue applying the correction. Please try again or contact support.' })
      }
    }
  }
}
function* processSagaEmployeeLinkBadge(action: typeof ActionCreators.SagaEmployeeLinkBadge) {
  const badgeData: EmployeeLinkBadePayload = action.payload
  let currentBadgeNumber: string = ''
  const entityHierarchyConfiguration = yield select(getEntityHierarchyConfiguration)
  const logData: EmployeeLogItem[] = yield select(StoreSelectors.getEmployeeLogData)
  try {
    yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
    yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
    const currentLogItem = logData.find(
      (logitem) =>
        logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID
    )
    currentBadgeNumber = currentLogItem.badgeNumber //get the current badge number in case we need to roll the assignment back
    const result = yield call([AWSHelper, AWSHelper.employeeApi.employeeLog.linkBadge], action.payload)
    if (result.key === 'BADGE_NR_UPDATED') {
      const clonedEmployeeLogData = cloneDeep(logData)
      const updatedLogs = clonedEmployeeLogData.map((logitem) => {
        if (logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID) {
          logitem.badgeNumber = badgeData.badgeNumber
        }
        return logitem
      })
      const timePairs = orderBy(
        generateEmployeeLogPairSearchString(timePairedEmployeeLogEvents(updatedLogs), entityHierarchyConfiguration),
        ['referenceTimeStamp'],
        ['desc']
      )
      yield put(
        StoreActionCreators.StoreSetEmployeeLogEventPairData.create({
          data: timePairs,
          shouldSubscribeToUpdates: true,
        })
      )
      yield put(StoreActionCreators.StoreSetEmployeeLogData.create(updatedLogs))
      action.callback({ status: 'success' })
    } else {
      throw new Error(result.key)
    }
  } catch (e) {
    if (e === 'No credentials') {
      yield put(AuthenticationActionCreators.SagaRefreshApiCredentials.create())
      yield take(AuthenticationActionCreators.SagaApiCredentialsRefreshUpdate.type)
      yield call(processSagaEmployeeLinkBadge, action)
    } else {
      const clonedEmployeeLogData = cloneDeep(logData)
      const updatedLogs = clonedEmployeeLogData.map((logitem) => {
        if (logitem.EntityHierarchy === badgeData.EntityHierarchy && logitem.evtTimeStampProfileID === badgeData.evtTimeStampProfileID) {
          if (currentBadgeNumber) {
            logitem.badgeNumber = currentBadgeNumber
          } else {
            delete logitem.badgeNumber
          }
        }
        return logitem
      })
      const timePairs = orderBy(
        generateEmployeeLogPairSearchString(timePairedEmployeeLogEvents(updatedLogs), entityHierarchyConfiguration),
        ['referenceTimeStamp'],
        ['desc']
      )
      yield put(
        StoreActionCreators.StoreSetEmployeeLogEventPairData.create({
          data: timePairs,
          shouldSubscribeToUpdates: true,
        })
      )
      yield put(StoreActionCreators.StoreSetEmployeeLogData.create(updatedLogs))
      if (e) console.error(e)
      if (action.callback) action.callback({ status: 'failed', message: e })
    }
  }
}

// Saga triggers
function* watchEmployeeLogSagas() {
  yield takeLeading(ActionCreators.SagaRetrieveEmployeeLogData.type, processRetrieveEmployeeLogData)
  yield takeLeading(ActionCreators.SagaRefreshEmployeeLogSubscriptionCredential.type, processRefreshEmployeeLogSubscriptionCredential)
  yield takeLeading(ActionCreators.SagaRejectEmployeeLogImage.type, processRejectEmployeeLogImage)
  yield takeLeading(ActionCreators.SagaAcceptEmployeeLogImage.type, processAcceptEmployeeLogImage)
  yield takeLeading(ActionCreators.SagaRemoveEmployeeReferenceImage.type, processRemoveEmployeeReferenceImage)
  yield takeLeading(
    ActionCreators.SagaEmployeeCorrectionConvertClockInToClockOut.type,
    processSagaEmployeeCorrectionConvertClockInToClockOut
  )
  yield takeLeading(ActionCreators.SagaEmployeeCorrectionAdjustClockInTime.type, processSagaEmployeeCorrectionAdjustClockInTime)
  // yield takeLeading(ActionCreators.SagaEmployeeCorrectionDeleteClockIn.type, processSagaEmployeeCorrectionDeleteClockIn)
  yield takeLeading(ActionCreators.SagaEmployeeCorrectionAdjustClockOutTime.type, processSagaEmployeeCorrectionAdjustClockOutTime)
  // yield takeLeading(ActionCreators.SagaEmployeeCorrectionDeleteClockOut.type, processSagaEmployeeCorrectionDeleteClockOut)
  yield takeLeading(ActionCreators.SagaEmployeeCorrectionCreateClockOut.type, processSagaEmployeeCorrectionCreateClockOut)
  yield takeLeading(ActionCreators.SagaEmployeeCorrectionCreateNewTimePair.type, processSagaEmployeeCorrectionCreateNewTimePair)
  yield takeLeading(ActionCreators.SagaEmployeeCorrectionAddComment.type, processSagaEmployeeCorrectionAddComment)
  yield takeLeading(ActionCreators.SagaEmployeeLinkBadge.type, processSagaEmployeeLinkBadge)

  yield null
}

function* watchEmployeeLiveUpdateChannel() {
  const SagaEmployeeLiveUpdateHandlerChannel = yield actionChannel(ActionCreators.SagaEmployeeLiveUpdateHandler.type)
  while (true) {
    const action = yield take(SagaEmployeeLiveUpdateHandlerChannel)
    yield call(processEmployeeLiveUpdateHandler, action)
  }
}

// Saga hooks
export default function* employeeLogSagas() {
  yield all([fork(watchEmployeeLogSagas), fork(watchEmployeeLiveUpdateChannel)])
}
